import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]

  return (
    <section className={'svgBoxRectanguloBottonRigth'}>

      <div className="pic-wrapper svgHerosection2  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-end  items-center">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[33px] mb-4 md:text-[35px] lg:text-[50px]">
              {title}
            </h1>
            <p className="text-white text-center  leading-6">
              {texts}
            </p>
          </div>

          <div className="w-full flex justify-center pt-5 pb-20">
            <ButtonContent btnStyle={'three'} />
          </div>

        </div>
      </div>
      <div className="w-full flex md:justify-end justify-start md:pr-28 pl-28 md:pl-0 ">
        <img className="relative z-10 md:w-[250px] w-[200px] h-auto md:-mt-28 -mt-10" alt="years" src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F10%20Years.png?alt=media&token=4a8697b6-cc1e-42e8-9577-7d780cfd2fb8&_gl=1*bx91ga*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5NzczNjc2My4yMDkuMS4xNjk3NzM5MjI3LjQzLjAuMA.." />
      </div>


    </section>

  )
}

export default HeroSection;
