import React from "react";

const TraslateHome = ({ cambiarId }) => {
    const id1 = "/paginaspanish/65303c5c41eb862834d94dd5"; //Variable en español
    const id2 = "/paginas/653038f041eb862834d94d7e"; //Variable en Ingles
    const CompanyLogo = 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F653038f041eb862834d94d7e%2FiconAndLogo%2F578%20Advisors%20LLC.png?alt=media&token=03579400-e353-4314-92c3-74b128def3eb'

    return (
        <div
            className="w-full h-screen relative flex items-center bg1"
        >
            <div className="relative flex flex-col items-center w-full">
                <img
                    src={CompanyLogo}
                    alt="no-logo"
                    className="w-[80%] md:w-[30%] pb-5"
                />
                <h1 className="text-white capitalize pb-5">Sitio / Site</h1>
                <div className="flex">
                    <div className="mr-3">
                        <button
                            onClick={() => cambiarId({id: id1, l: 'es'})}
                            className="px-5 py-3 rounded-2xl bg-blue-600 text-slate-50 hover:scale-105 transition-all"
                        >
                            Español
                        </button>
                    </div>
                    <div className="ml-3">
                        <button
                            onClick={() => cambiarId({id: id2, l: 'en'})}
                            className="px-5 py-3 rounded-2xl bg-blue-600 text-slate-50 hover:scale-105 transition-all"
                        >
                            English
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TraslateHome