/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const Map = ({svgClass, title}) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (

    <div className="pb-20 md:pb-28">
      <div className=" bgColor1 py-5">
        {
          title ?
            <h2 className="text-center">
              {title}
            </h2>
            :
            <h2 className="text-center text-white">
              {
                !rpdata?.spanishVersion?.isActive ?
                  <span>
                  Cubrimos {rpdata?.dbPrincipal?.miles} millas alrededor de {rpdata?.dbPrincipal?.location?.[0].address}
                  </span>
                  :
                  <span>
                  We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
                  </span>
            }
            </h2>
        }
        {
          rpdata?.dbPrincipal?.location.length > 2 ?
            <ul className="pt-4 pb-4 md:p-0 flex flex-wrap justify-between md:justify-center items-center ">
              {rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <Link to="/contact" key={index}>
                    <li className="py-2 px-3 flex items-center">
                      <FiMapPin fontSize={25} />
                      <span className="pl-2">{item.address}</span>
                    </li>
                  </Link>
                );
              })}
            </ul>
            : null
        }
      </div>
      <div className={svgClass ? svgClass : `svgTriangulobotton`}>
        <iframe
          src={rpdata?.dbPrincipal?.location?.[0].url}
          className="w-full h-[550px]"
          title="Maps"
        />
      </div>
    </div>
  )
}

export default Map
